<template>
    <div v-if="model" class="container">
        <div class="modal sequence-modal-step">
            <div class="modal__information">
                <div class="center" v-html="text"></div>
            </div>
            <div class="modal__button button">
                <button
                        v-for="button in buttons"
                        class="button__confirm"
                        @click="() => button.action({nextStep: confirm})"

                        v-html="button.text"
                >
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {SequenceStepWithSequenceAlertModalDefinition} from "../../sequences/SequenceStep/step_with_sequence_alert_modal";

    export default {
        name: "SequencesAlertModal",
        computed: {
            activeStepDefinition() {
                return this.$store.getters['sequences_store/getActiveStep']?.definition
            },
            model() {
                return this.activeStepDefinition && this.activeStepDefinition instanceof SequenceStepWithSequenceAlertModalDefinition
            },
            text() {
                return this.model && this.activeStepDefinition?.text
            },
            buttons() {
                return this.model && this.activeStepDefinition?.buttons
            },
            // backButton() {
            //     return this.model && this.$store.getters['sequences_store/getActiveStep']?.definition?.backButton
            // }
        },
        methods: {
            async confirm() {
                await this.$store.dispatch('sequences_store/takeSnapshot', {stepId: this.activeStepDefinition?.id})
                await this.$store.dispatch('sequences_store/processChunkAction')

            }
        }
    }
</script>

<style scoped lang="scss">
    .sequence-modal-step {
        text-align: left;
        border-radius: 2px;
    }
</style>

<style scoped lang="scss">
    @import "../../scss/mixin";
    .container {
        @include modal-container;


        .modal {
            @include modal;

            &__information {

                font-weight: 500;
                line-height: 20px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }

            .center {
                text-align: center;

                ::v-deep p {
                  margin-bottom: 10px;
                }

                ::v-deep img {
                  margin-right: 30px;
                }
            }

            &__image {
                height: auto;
                width: 62%;
                margin: 20px auto;
                img {
                    max-width: 100%;
                }
            }

            &__button {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                column-gap: 20px;
            }

            .button {
                &__confirm {
                    @include button-modal;
                }
            }
        }
    }
</style>